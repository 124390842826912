// src/pages/SignupPage.js
import React from 'react';

const Return = () => {
    return (
        <div>
            
        </div>
    );
};

export default Return;